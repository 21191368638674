import React, { useContext, useEffect, useState } from "react"
import { Box, Flex, Image as NormalImage } from "theme-ui"
import { ImageContainer } from "../components/base/image"
import Text from "../components/base/text/text"
import {
  Product,
  ProductContainer,
} from "../components/product/product-highlight"
import StoreContext from "../context/store-context"
import Medusa from "../services/api"
import { createContentfulClient } from "../util/contentful"
import { formatVariantPrice } from "../util/prices"

const SearchPage = () => {
  const contentfulClient = createContentfulClient()
  const [searchState, setSearchState] = useState("")
  const [hits, setHits] = useState([])
  const [contentfulHits, setContentfulHits] = useState([])

  var queryString
  if (typeof window !== "undefined") {
    queryString = window?.location.search
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(queryString)
    const searchParam = urlParams?.get("q")
    if (searchParam?.length > 1) {
      setSearchState(searchParam)
    }
  }, [queryString])

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      if (searchState) {
        Medusa.products
          .search(searchState, { limit: 20 })
          .then((res) => res.data)
          .then((data) => setHits(data.hits))
      }
    }, 500)

    return () => clearTimeout(debouncedSearch)
  }, [searchState])

  // served by their CDN which ensures a good performance
  const fetchContentfulImages = async () => {
    const queryObject = {
      content_type: "product",
      // fetch all products where object id is part of MeiliSearch hits
      "sys.id[in]": hits.map((h) => h.id).join(","),
      // only select id and image
      select: "fields.primaryImage,fields.objectId,fields.handle",
    }

    const entries = await contentfulClient.getEntries(queryObject)
    setContentfulHits(entries.items)
  }

  useEffect(() => {
    if (hits?.length > 0) {
      fetchContentfulImages()
    }
  }, [hits])

  return (
    <Box mx={["1%", "auto"]} sx={{ maxWidth: ["100%", "80%"] }}>
      <ProductContainer>
        {hits.map((node) => {
          const contentfulProduct = contentfulHits.find(
            (h) => h.fields.objectId === node.id
          )
          if (contentfulProduct) {
            return (
              <ProductHit
                key={`hit-${node.id}`}
                product={node}
                productImages={contentfulHits}
                slugs={{
                  categorySlug: node.collection_handle || "",
                  productSlug: contentfulProduct.fields.handle,
                  fullSlug: node.fullSlug || "",
                }}
              />
            )
          }
        })}
      </ProductContainer>
    </Box>
  )
}

const ProductHit = ({ product, slugs, productImages }) => {
  const { id, title, color } = product
  const { categorySlug, productSlug, fullSlug } = slugs
  const { cart } = useContext(StoreContext)

  const extractImages = () => {
    const primaryImage = productImages?.find(
      (el) => el.fields.objectId === product.id
    )?.fields?.primaryImage

    if (primaryImage?.fields?.file?.url) {
      // this is the recommended way according to Contentful docs
      return {
        primary: `https:${primaryImage?.fields?.file?.url}?fm=jpg&fl=progressive`,
      }
    } else {
      // intentionally dont want to show the product thumbnail as default
      // instead just omit the product from the grid
      return null
    }
  }

  if (!extractImages()) {
    return null
  }

  return (
    <Product key={id} to={fullSlug ? fullSlug : `/all/${productSlug}`}>
      <Flex sx={{ flex: 1 }}>
        <Image {...extractImages()} />
      </Flex>
      <Flex sx={{ flexDirection: "column" }} mt={2} mb={3}>
        <Text
          sx={{
            fontSize: [0, 1],
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Text>
        <Text sx={{ fontSize: 0 }}>{color?.name}</Text>
        <Text sx={{ fontSize: 0 }}>
          {formatVariantPrice(cart, product.variants[0])}
        </Text>
      </Flex>
    </Product>
  )
}

const Image = ({ primary, secondary, alt }) => {
  return (
    <ImageContainer secondary={secondary}>
      <NormalImage
        className="primary"
        src={primary}
        alt={alt || " "}
        sx={{ aspectRatio: "4 / 5", width: "100%" }}
        height="500px"
        width="400px"
      />
      <NormalImage
        className="secondary"
        src={primary}
        alt={alt || " "}
        sx={{ aspectRatio: "4 / 5", width: "100%" }}
        height="500px"
        width="400px"
      />
    </ImageContainer>
  )
}

export default SearchPage
