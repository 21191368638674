import { createClient } from "contentful"

const space = process.env.GATSBY_CONTENTFUL_SPACE_ID || "yn04o8yn9gx8"
const delivery =
  process.env.GATSBY_CONTENTFUL_DELIVERY_TOKEN ||
  "bfC9w-s4jInJ-rQhZXaRLiDcM69qCSsY-fBKI2MdjHU"
const environment = process.env.GATSBY_CONTENTFUL_ENV || "development"

export const createContentfulClient = () =>
  createClient({ space, accessToken: delivery, environment })
